import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/solid'
import { addressTruncate } from './utils/address-truncate'
import {
  useAccount,
  useConnect,
  useDisconnect,
} from 'wagmi'
import { WalletConnectModal } from './WalletConnectModal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const WalletConnectionManager = () => {
    const { data: account } = useAccount()
    const [ isModalOpen, setIsModalOpen] = useState(false)

    const { connect, connectors, error, isConnecting, pendingConnector } =
      useConnect()
    const { disconnect } = useDisconnect()

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full ">
          <UserCircleIcon className="h-10 w-10 text-black" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            { account && (
              <Fragment>
                <Menu.Item>
                  <p className={classNames(
                    'text-gray-700',
                      'block px-4 py-2 text-sm')}
                  >{ addressTruncate(account.address) }</p>
                </Menu.Item>

                <Menu.Item>
                {({ active }) => (
                  <button 
                    type="submit" 
                    onClick={disconnect}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm'
                    )}
                  >
                    Log out
                  </button>
                )}
                </Menu.Item>
              </Fragment>
            )}

            { !account && (
              <Menu.Item>
                {({ active }) => (
                  <button 
                    type="submit" 
                    onClick={() => setIsModalOpen(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm'
                    )}
                  >
                    Connect Wallet
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
      <WalletConnectModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Menu>
  )
}