
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useContractRead, useProvider } from 'wagmi'
import { BigNumber, utils } from 'ethers';
import { BallTriangle } from 'react-loader-spinner'

import { PositionImage } from './PositionImage'
import ensRegistryABI from './contract-abi.json'
import { etherscanTokenUrl } from "./utils/etherscan-token-url";
import { poll as poller } from './utils/poll'

export const Position = ({ poll = false, overridePosition = null }) => {
    const { positionId: paramPositonId } = useParams();
    const [ metadataUri, setMetadataUri ] = useState("")
    const [ metadata, setMetadata ] = useState(null)

    const positionId = overridePosition || paramPositonId;

    const isTokenIdCreated = useContractRead({
        addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractInterface: ensRegistryABI,
    }, 'isTokenIdCreated', { args: positionId })

    const addressByTokenId = useContractRead({
        addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractInterface: ensRegistryABI,
    }, 'addressByTokenId', { args: positionId })

    const uriRead = useContractRead({
        addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractInterface: ensRegistryABI,
    }, 'uri', { args: positionId })

    useEffect(() => {
        if (isTokenIdCreated.isSuccess && uriRead.data) {
            const value = utils.hexZeroPad(BigNumber.from(positionId), 32).substring(2)
            const transformedUri = uriRead.data.replace('{id}', value)
            setMetadataUri(transformedUri)
        }
    }, [isTokenIdCreated, uriRead])

    const getMetadata = async (uri) => {
        const fetcher = () => fetch(uri, { 
            method: 'GET', 
            headers: {
                'Accept': 'application/json'
            }
        })
        
        let response = null;
        if (poll) {
            response = await poller({ fn: fetcher, validate: (result) => result.ok, interval: 1000, maxAttempts: 180 })
        } else {
            response = await fetcher()
        }

        const json = await response.json()
        setMetadata(json)
    }
 
    useEffect(() => {
        if (metadataUri) {
            getMetadata(metadataUri)
        }
    }, [metadataUri]);

    const etherscanUrl = useMemo(() => {
        return etherscanTokenUrl(process.env.REACT_APP_CONTRACT_ADDRESS, positionId)
    }, [positionId])

    if (isTokenIdCreated.isFetching || addressByTokenId.isFetching) {
        return null
    }

    if (!isTokenIdCreated.data) {
        return <div>Nobody is in position {positionId}.</div>
    }

    if (poll && !metadata) {
        return (
            <div className='flex justify-center flex-col items-center'>
                <BallTriangle
                    heigth="50"
                    width="50"
                    color="grey"
                    ariaLabel="loading-indicator"
                    className="mb-4"
                  />
                <p>Generating metadata. Hang tight - Might take up to a minute...</p>
            </div>
        )
    }

    return (
        <div>
            <div className={"flex flex-row justify-center text-center mb-8"}>
                <div className={"w-1/3"} >
                    <PositionImage src={metadata?.image} />
                </div>
            </div>
            <p className="text-sm mb-2">
                Owner:<br />
                <a 
                    href={etherscanUrl}
                    target="_blank"
                    className="hover:text-blue-500"
                >
                    {addressByTokenId.data}
                </a>
            </p>
            <p className="text-sm">
            <a 
                    href={`https://opensea.io/assets/ethereum/${process.env.REACT_APP_CONTRACT_ADDRESS}/${positionId}`}
                    target="_blank"
                    className="hover:text-blue-500"
                >
                    View on OpenSea
                </a>
            </p>
        </div>
    )
}