import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useConnect } from 'wagmi'

import metamaskLogo from './icons/metamask-fox.svg'
import coinbaseLogo from './icons/coinbase-wallet.png'
import walletconnectLogo from './icons/walletconnect-logo.svg'

export const WalletConnectModal = ({ isOpen, setIsOpen }) => {
  const { connect, connectors, error, isConnecting, pendingConnector } =
      useConnect({
        onConnect: (data) => {
          setIsOpen(false)
        }
      })

  const cancelButtonRef = useRef(null)

  const getLogo = (id) => {
    switch(id) {
      case "metaMask":
        return metamaskLogo;
      case "coinbaseWallet":
        return coinbaseLogo;
      case "walletConnect":
        return walletconnectLogo;
      default:
        return null
    }
  } 

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex flex-col mx-4 space-y-5 bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {connectors.map((connector) => {
                    return (
                        <button
                          disabled={!connector.ready}
                          key={connector.id}
                          onClick={() => connect(connector)}
                        >
                            <div className="grid grid-cols-4 items-center">
                              <img src={getLogo(connector.id)} className="h-12 w-12" />
                              <p className='font-bold col-span-3 text-left'>
                                {connector.name}
                                {!connector.ready && ' (unsupported)'}
                                {isConnecting &&
                                  connector.id === pendingConnector?.id &&
                                  ' (connecting)'}
                              </p>
                            </div>
                        </button>
                    )
                  })}

                  {error && <div>{error.message}</div>}                   
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
