import { useContractWrite, useAccount, useWaitForTransaction } from 'wagmi'
import ensRegistryABI from './contract-abi.json'
import { Button } from './components/button';
import { UserCircleIcon } from '@heroicons/react/solid'

import { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner'
import { Position } from './Position'


export const Join = () => {
    const { data: account } = useAccount()
    const [token, setToken] = useState("")
    const [txProgress, setTxProgress] = useState("")

    const { 
      data: writeData, 
      isError: isWriteError, 
      isIdle: isWriteIdle, 
      isSuccess: isWriteSuccess, 
      status: writeStatus, 
      isLoading: isWriteLoading, 
      write 
    } = useContractWrite(
      {
        addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractInterface: ensRegistryABI,
      },
      'joinWaitlist',
      {
        onError: (error) => {
          setTxProgress(error.message)
          console.log('Error', error)
        },
        onSettled: (data, error) => {
          console.log('Settled', { data, error })
        },
        onSuccess: (data) => {
          setTxProgress('Transaction received...')
          console.log('Success', data)
        }
      }
    )

    const { 
      data: waitForData, 
      isError: isTxError, 
      isSuccess: isTxSuccess,
      isLoading: isTxLoading,
      isFetching: isTxFetching,
      isRefetching: isTxRefetching,
      isIdle, isTxIdle
    } = useWaitForTransaction({
      hash: writeData?.hash,
      wait: writeData?.wait,
      enabled: true,
      onError: (error) => {
        setTxProgress(error.message)
        console.log('Error', error)
      },
      onSuccess: (data) => {
        setTxProgress('Transaction completed...')

        const tokenHex = data?.logs[0].data.substring(2).substring(64*3, 64*4)
        const tokenDecimal = parseInt(tokenHex, 16)
        setToken(tokenDecimal)
        setTxProgress("")
      }
    })
        
    return (
      <div className='container mx-auto flex flex-col justify-center text-center mb-6 space-y-8 items-center'>
          <h1 className='z-0 font-marker md:text-8xl -skew-y-6 mb-6 text-6xl'>Join<br/>Waitlist</h1>
          <p>It's free to join and you own your position in line. <br /> Sell it. Hold it. Whatever.</p>

          <div className='flex justify-center'>
            {((isWriteLoading || isTxLoading) && (!isWriteError && !isTxError)) &&
              <BallTriangle
                heigth="50"
                width="50"
                color="grey"
                ariaLabel="loading-indicator"
              />
            }
          </div>

          {!account && (
            <div className='flex items-center'>
              <p>Connect wallet to join</p>
              <UserCircleIcon className="h-10 w-10 text-black" />
            </div>
          )}

          <div>
            {((isWriteIdle || isWriteError) && account)  && 
              <Button onClick={() => { 
                setTxProgress('Connecting to wallet...')
                write({ args: [1] }
              )}}>Join Waitlist</Button> 
            }
          </div>

          {txProgress && <p>{txProgress}</p>}

          { isTxSuccess && <Position poll={true} overridePosition={token} /> }
      </div>
    )
}