import { useState } from "react"
import { Outlet, Link } from "react-router-dom"

import { WalletConnectionManager } from "./WalletConnectionManager"

export const Layout = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div className="min-h-screen  mx-auto py-6 flex justify-center">
            <div className="container mx-auto flex justify-start flex-col">
                <div class="md:hidden flex items-center ml-4">
                        <button class="outline-none mobile-menu-button" onClick={() => {handleMenuClick()}}>
                            <svg
                                class="w-6 h-6 text-gray-500"
                                x-show="!showMenu"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                            <path d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                <nav className={`${!menuOpen && 'hidden'} mb-8 z-10 w-full md:block md:w-auto ml-4`}>
                    <ul className="flex flex-col mt-4 space-y-3 md:space-y-0 md:flex-row md:justify-end md:items-center md:space-x-8 md:mt-0 md:text-sm">
                        <li>
                            <Link to="/" onClick={() => handleMenuClick()}>Home</Link>
                        </li>
                        <li>
                            <Link to="/join" onClick={() => handleMenuClick()}>Join Waitlist</Link>
                        </li>
                        <li>
                            <Link to="/owner" onClick={() => handleMenuClick()}>Owners</Link>
                        </li>
                        <li>
                            <a href="https://opensea.io/collection/waitlist" target="_blank">OpenSea</a>
                        </li>
                        <li>
                            <a href="https://etherscan.io/address/0x8847984613f12b8bebe4a5c2e721576166b91acc" target="_blank">Etherscan</a>
                        </li>
                        <li>
                            <WalletConnectionManager />
                        </li>
                    </ul>
                </nav>
                
                

                <div className="flex justify-center">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}