export const Button = ({ children, disabled, onClick}) => {
    return (
        <button 
            disabled={disabled} 
            type="submit" 
            onClick={onClick} 
            className={`px-3 ${!disabled ? "text-gray-700" : "text-gray-300"} border-2 ${!disabled ? "border-black" : "border-gray-300"} py-1 ${!disabled && "hover:bg-black hover:text-white"}`}
        >
            {children}
        </button>
    )
}