import { WagmiProvider, createWagmiClient, chain, defaultChains } from 'wagmi'
import { providers } from 'ethers'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { Buffer } from 'buffer';
import { Routes, Route, Link } from "react-router-dom";

import { Home } from './Home';
import { Position } from './Position';
import { Owner } from './Owner';
import { Join } from './Join'
import { Layout } from './Layout';
import { Connect } from './Connect';
import { NoMatch } from './NoMatch'

Buffer.from('anything','base64');

const infuraId = process.env.REACT_APP_INFURA_ID
const network = process.env.REACT_APP_NETWORK

const chains = defaultChains
const defaultChain = chain[network]

// Set up connectors
const client = createWagmiClient({
  autoConnect: true,
  connectors({ chainId }) {
    const chain = chains.find((x) => x.id === chainId) ?? defaultChain
    const rpcUrl = chain.rpcUrls.infura
      ? `${chain.rpcUrls.infura}/${infuraId}`
      : chain.rpcUrls.default
    return [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'wagmi',
          chainId: chain.id,
          jsonRpcUrl: rpcUrl,
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
          rpc: { [chain.id]: rpcUrl },
        },
      }),
    ]
  },
  provider(config) {
    return new providers.InfuraProvider(network, infuraId)
  },
})

const App = () => (
  <WagmiProvider client={client}>
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="join" element={<Join />} />
        <Route path="owner" element={<Owner />}>
          <Route path=":positionId" element={<Position />} />
        </Route>
        <Route path="connect" element={<Connect />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  </WagmiProvider>
)

export default App;
