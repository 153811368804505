import { Link } from "react-router-dom"
import { PositionImage } from './PositionImage';

export const PositionImageSerialList = ({ lastPosition }) => {
    return (
        <div className="flex w-2/3 flex-wrap p-3">
            {[...Array(lastPosition)].map((e, i) => {
                const token = i + 1
                return (
                    <div className={"flex-grow w-1/4 m-4"} key={i}>
                        <Link to={`/owner/${token}`}>
                            <PositionImage src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${process.env.REACT_APP_CONTRACT_ADDRESS.toLowerCase()}/${token}.png`} />
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}