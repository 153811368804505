import * as React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from './components/button';

export const Owner = () => {
    let navigate = useNavigate();
    const [inputValue, setInputValue] = React.useState("");

    const onChangeHandler = event => {
        setInputValue(event.target.value);
     };

     const goToPosition = () => {
        navigate(`/owner/${inputValue}`)
     }

    return (
        <div className='container mx-auto flex flex-col justify-center text-center mb-6 space-y-8 items-center'>
            <h1 className='z-0 font-marker md:text-8xl -skew-y-6 mb-6 text-6xl'>Owners</h1>

            <div className='flex flex-col space-y-5 md:space-y-0 md:flex-row justify-center space-x-8 md:items-center w-1/2 md:w-full'>
                <label htmlFor="price" className=" text-gray-700">Position Number</label>
                <div className="relative rounded-md shadow-sm">
                    <input
                        type="number"
                        name="position"
                        id="position"
                        value={inputValue} 
                        min="1"
                        onChange={onChangeHandler}
                        className="pl-2 focus:ring-indigo-500 focus:border-indigo-500 w-full border-2 border-gray-700 rounded-md"
                    />
                </div>
                <Button disabled={!inputValue || inputValue <= 0} onClick={goToPosition}>Find</Button>
            </div>
            <Outlet />
        </div>
    )
}